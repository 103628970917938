import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import "./RadioButton.scss";
import ErrorMessage from "@/components/ErrorMessage";

// eslint-disable-next-line react/display-name
const RadioButton = React.forwardRef(
  (
    {
      text,
      value,
      render,
      onChange,
      onBlur,
      errors,
      isDefault,
      isCustomButton,
      name,
      isChecked
    },
    ref
  ) => {
    return (
      <div
        className={cn("radio-wrapper", {
          "has-error": !!errors[name],
          "form-group not-default": !isDefault && !isCustomButton,
          default: isDefault,
          "radio-wrapper-button": isCustomButton
        })}>
        <input
          ref={ref}
          type="radio"
          className="radio-control"
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          id={`field-${name}-${value}`}
          defaultChecked={isChecked}
        />
        <label
          className={cn("radio-button-instance", { "radio-button": !render })}
          htmlFor={`field-${name}-${value}`}>
          {render ? (
            render({
              value,
              text,
              hasError: !!errors[name]
            })
          ) : (
            <span className="radio-button__title">{text}</span>
          )}
        </label>
        <ErrorMessage errors={errors} name={name} />
      </div>
    );
  }
);

RadioButton.defaultProps = {
  errors: {},
  render: null,
  isDefault: false
};

RadioButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  isDefault: PropTypes.bool,
  isCustomButton: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
    PropTypes.bool.isRequired
  ]),
  render: PropTypes.func,
  isChecked: PropTypes.bool
};

export default RadioButton;
