import React, { useEffect } from "react";
import {
  OrderCreation,
  ApplicationInfo,
  OrderVerification,
  Agreement,
  OrderCart,
  // OrderPayment,
  PaymentType
  // OrderPaymentStatus
} from "@/components/OrderSteps";
import { Routes, Route, useParams, Outlet, Navigate } from "react-router-dom";
import { DrawerLayout } from "@/layouts";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails } from "@/store/order/order.reducer";
import { getOrderDetailsById } from "@/store/order/order.actions";
import OTPConfirmation from "@/components/OrderSteps/OrderVerification/OTPConfirmation";
import SuccessBox from "@/components/SuccessBox";
import { routes } from "@/constants";

const DetailsSupplier = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const orderDetails = useSelector(getOrderDetails);

  useEffect(() => {
    if (id) {
      dispatch(getOrderDetailsById(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (id && !orderDetails) {
    return null;
  }

  return <Outlet />;
};

const OrderPage = () => {
  return (
    <Routes>
      <Route element={<DetailsSupplier />}>
        <Route element={<DrawerLayout />}>
          <Route index element={<Navigate to={routes.NEW_ORDER} />} />
          <Route path="/:id" element={<OrderCreation />} />
          <Route path="/:id/application-info" element={<ApplicationInfo />} />
          <Route path="/:id/verify" element={<OrderVerification />} />
          <Route path="/:id/otp" element={<OTPConfirmation />} />
          <Route path="/:id/agreement" element={<Agreement />} />
          <Route path="/:id/cart" element={<OrderCart />} />
          {/* <Route path="/:id/payment" element={<OrderPayment />} /> */}
          <Route path="/:id/payment" element={<PaymentType />} />
          <Route
            path="/:id/payment-success"
            element={
              <SuccessBox
                title="Payment received"
                subtitle="Your payment has been successfully processed. The assessment process will unfold based on the plan you have opted for!"
                button={{
                  label: "Go to dashboard",
                  url: routes.ROOT
                }}
              />
            }
          />
          <Route
            path="/:id/invoice-success"
            element={
              <SuccessBox
                title="Invoicing information received"
                subtitle="Thank you for providing the invoicing details. Our team is processing the information, and we will be in touch shortly."
                button={{
                  label: "Go to dashboard",
                  url: routes.ROOT
                }}
              />
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default OrderPage;
