import { ApiClient } from "@/services/api";

class AuthService extends ApiClient {
  createOrder(params) {
    // developer info
    return this.apiClient.post("/api/v1/order", params);
  }

  updateDeveloperInfo({ id, ...params }) {
    // developer info
    return this.apiClient.post(`/api/v1/order/${id}/developer-info`, params);
  }

  getOrderDetails(id) {
    return this.apiClient.get(`/api/v1/order/${id}`);
  }

  sendApplicationInfo({ id, data }) {
    return this.apiClient.post(`/api/v1/order/${id}/application-info`, data);
  }

  // eslint-disable-next-line camelcase
  sendVerificationInfo({ id, is_manual_verification }) {
    return this.apiClient.post(`/api/v1/order/${id}/verification-info`, {
      // eslint-disable-next-line camelcase
      is_manual_verification
    });
  }

  resendOtpVerification(id) {
    return this.apiClient.post(`/api/v1/order/${id}/verification-resend-otp`);
  }

  verifyOtpCode({ id, otp }) {
    return this.apiClient.post(`/api/v1/order/${id}/verification-verify-otp`, {
      otp
    });
  }

  sendAgreement({ id, agree }) {
    return this.apiClient.post(`/api/v1/order/${id}/agreement`, {
      agree
    });
  }

  // eslint-disable-next-line camelcase
  fetchPayment({ id, product_id }) {
    return this.apiClient.post(`/api/v1/order/${id}/payment`, {
      // eslint-disable-next-line camelcase
      product_id
    });
  }

  sendMitigationsRecheck({ id }) {
    return this.apiClient.post(
      `/api/v1/order/${id}/mitigations-needed/recheck`,
      null
    );
  }

  getProducts() {
    return this.apiClient.get("/api/v1/products");
  }

  getOrders(page, status) {
    return this.apiClient.get("/api/v2/orders", {
      params: {
        page,
        status
      }
    });
  }

  fetchOrderTableCount() {
    return this.apiClient("/api/v1/orders/count");
  }

  getServices() {
    return this.apiClient.get("/api/v1/services/list");
  }

  createOrderCasa(params) {
    return this.apiClient.post("/api/v2/order-casa", params);
  }

  updateDeveloperInfoCasa({ id, ...params }) {
    return this.apiClient.post(
      `/api/v2/order-casa/${id}/developer-info`,
      params
    );
  }

  getOrderCasaDetails(id) {
    return this.apiClient.get(`/api/v2/order-casa/${id}`);
  }

  sendApplicationInfoCasa({ id, data }) {
    return this.apiClient.post(
      `/api/v2/order-casa/${id}/application-info`,
      data
    );
  }

  sendAssesmentInfo({ id, data }) {
    return this.apiClient.post(
      `/api/v2/order-casa/${id}/assessment-info`,
      data
    );
  }

  sendSourceCodeInfo({ id, data }) {
    return this.apiClient.post(
      `/api/v2/order-casa/${id}/source-code-info`,
      data
    );
  }

  // eslint-disable-next-line camelcase
  fetchPaymentCasa({ id, plane_id }) {
    return this.apiClient.post(`/api/v2/order-casa/${id}/payment`, {
      // eslint-disable-next-line camelcase
      plane_id
    });
  }

  readyForRevalidationCasa(id) {
    return this.apiClient.post(
      `/api/v2/order-casa/${id}/ready-for-revalidation`
    );
  }

  sendPurchaseOrder({ id, data }) {
    return this.apiClient.post(`/api/v2/order-casa/${id}/purchase-order`, data);
  }

  sendInvoiceCasa({ id, data }) {
    return this.apiClient.post(`/api/v2/order-casa/${id}/invoice`, data);
  }

  sendInvoice({ id, data }) {
    return this.apiClient.post(`/api/v1/order/${id}/invoice`, data);
  }

  removeAssesmentFileCasa(id) {
    return this.apiClient.post(
      `/api/v2/order-casa/${id}/assessment-info/file/unlink`
    );
  }

  removeSourceCodeFileCasa(id) {
    return this.apiClient.post(
      `/api/v2/order-casa/${id}/source-code-info/file/unlink`
    );
  }

  // removePurchaseOrderFileCasa(id) {
  //   return this.apiClient.post(
  //     `/api/v2/order-casa/${id}/purchase-order/file/unlink`
  //   );
  // }

  removeProjectCasa(id) {
    return this.apiClient.post(`/api/v2/order-casa/${id}/delete`);
  }

  removeProjectMasa(id) {
    return this.apiClient.post(`/api/v1/order/${id}/delete`);
  }

  sendAgreementCasa({ id, agree }) {
    return this.apiClient.post(`/api/v2/order-casa/${id}/agreement`, {
      agree
    });
  }

  readyForRevalidation(id) {
    return this.apiClient.post(`/api/v1/order/${id}/ready-for-revalidation`);
  }
}

export default new AuthService();
