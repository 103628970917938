// for react-router-dom useNavigate only!
export const routes = {
  ROOT: "/",
  LOGIN: "/auth/login",
  REGISTER: "/auth/register",
  FORGOT_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: "/auth/forgot-password/reset",
  RESET_PASSWORD_SUCCESS: "/auth/forgot-password/success",
  REGISTER_CONFIRMATION: "/auth/register/confirmation",
  MFA: "/auth/mfa",
  MFA_SETUP_SUCCESS: "/auth/mfa-setup-success",
  MFA_ENABLE: "/mfa-enable",
  MFA_DISABLE: "/mfa-disable",
  MFA_ENABLE_SUCCESS: "/mfa-enable-success",
  MFA_DISABLE_SUCCESS: "/mfa-disable-success",
  START_ORDER: "/start-order",
  NEW_ORDER_CASA: "/new-order-casa",
  NEW_ORDER: "/new-order",
  ORDER_CASA_APPLICATION_INFO: "/order-casa/:id/application-info",
  ORDER_CASA_ASSESSMENT_INFO: "/order-casa/:id/assessment-info",
  ORDER_CASA_SOURCE_CODE_INFO: "/order-casa/:id/source-code-info",
  ORDER_CASA_SUCCESS: "/order-casa/:id/success",
  ORDER_CASA_BY_ID: "/order-casa/:id",
  ORDER_CASA_PAYMENT: "/order-casa/:id/payment",
  ORDER_CASA_PAYMENT_TYPE: "/order-casa/:id/payment-type",
  ORDER_CASA_AGREEMENT: "/order-casa/:id/agreement",
  ORDER_CASA_SUCCESS_PAYMENT: "/order-casa/:id/payment-success",
  ORDER_CASA_SUCCESS_INVOICE: "/order-casa/:id/invoice-success",
  PROJECT_DETAILS: "/project-details/:id",
  ORDER_BY_ID: "/order/:id",
  FAQ: "/faq",
  SETTINGS: "/settings",
  PROJECTS: "/projects",
  PROJECT_PROGRESS: "/project-progress/:id",
  ORDER_APPLICATION_INFO: "/order/:id/application-info",
  ORDER_VERIFY: "/order/:id/verify",
  OTP_CONFIRMATION: "/order/:id/otp",
  AGREEMENT: "/order/:id/agreement",
  CART: "/order/:id/cart",
  ORDER_PAYMENT: "/order/:id/payment",
  ORDER_PAYMENT_SUCCESS: "/order/:id/payment-success",
  ORDER_SUCCESS_INVOICE: "/order/:id/invoice-success",
  PRIVACY_POLICY: "/privacy",
  TERMS: "/terms",
  CHANGE_PASSWORD: "/change-password",
  CHANGE_PASSWORD_SUCCESS: "/change-password/success"
};
