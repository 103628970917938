export { ReactComponent as ArrowIcon } from "./arrow.svg";
export { ReactComponent as LogoutIcon } from "./logout.svg";
export { ReactComponent as ArrowRightIcon } from "./arrow-right.svg";
export { ReactComponent as IconBack } from "./icon-back.svg";
export { ReactComponent as IconForward } from "./icon-forward.svg";
export { ReactComponent as CheckIcon } from "./check.svg";
export { ReactComponent as UploadIcon } from "./upload-cloud-icon.svg";
export { ReactComponent as AssessmentProgressIcon } from "./assessment-in-progress.svg";
export { ReactComponent as CheckCircleDisabledIcon } from "./check-circle-disabled.svg";
export { ReactComponent as CheckCircleSuccessIcon } from "./check-circle-success.svg";
export { ReactComponent as CircleProgressIcon } from "./circle-progress.svg";
export { ReactComponent as CircleWarningIcon } from "./circle-warning.svg";
export { ReactComponent as FixIcon } from "./fix-icon.svg";
export { ReactComponent as MinusIcon } from "./minus-icon.svg";
export { ReactComponent as MoreInfoIcon } from "./more-info.svg";
export { ReactComponent as PaymentAbsentIcon } from "./payment-absent.svg";
export { ReactComponent as PlusIcon } from "./plus-icon.svg";
export { ReactComponent as VerificationAbsentIcon } from "./verification-absent.svg";
export { ReactComponent as AssessmentSuccessIcon } from "./assessment-success.svg";
export { ReactComponent as MitigationsSuccessIcon } from "./mitigations-success.svg";
export { ReactComponent as MoreInfoSuccessIcon } from "./more-info-success.svg";
export { ReactComponent as PaymentSuccessIcon } from "./payment-succeed.svg";
export { ReactComponent as VerificationSuccessIcon } from "./verification-success.svg";
export { ReactComponent as RemoveIcon } from "./remove-icon.svg";
export { ReactComponent as CrossRedIcon } from "./cross-red-icon.svg";
export { ReactComponent as EyeIcon } from "./eye.svg";
export { ReactComponent as EyeCrossedIcon } from "./eye-crossed.svg";
export { ReactComponent as AmazonIcon } from "./amazon-logo.svg";
export { ReactComponent as AppleLogo } from "./apple-logo.svg";
export { ReactComponent as GoogleIcon } from "./google-icon.svg";
export { ReactComponent as InfoIcon } from "./info-icon.svg";
export { ReactComponent as NotificationIcon } from "./notification.svg";
export { ReactComponent as DashboardIcon } from "./dashboard.svg";
export { ReactComponent as SettingsIcon } from "./settings.svg";
export { ReactComponent as QuestionMarkIcon } from "./question-mark-icon.svg";
export { ReactComponent as LockIcon } from "./lock-icon.svg";
export { ReactComponent as NotificationInvertedIcon } from "./notification-inverted-icon.svg";
export { ReactComponent as TrashIcon } from "./trash-icon.svg";
export { ReactComponent as TrashInvertedIcon } from "./trash-inverted-icon.svg";
export { ReactComponent as MailIcon } from "./mail-fill.svg";
export { ReactComponent as DeleteIcon } from "./delete.svg";
export { ReactComponent as FileIcon } from "./file.svg";
export { ReactComponent as DownloadIcon } from "./download.svg";
export { ReactComponent as ClockIcon } from "./clock.svg";
export { ReactComponent as TagIcon } from "./hashtag.svg";
