import React from "react";
import PropTypes from "prop-types";
import RadioButton from "@/components/RadioButton";
import "./RadioGroup.scss";

// eslint-disable-next-line react/display-name
const RadioGroup = React.forwardRef(
  (
    {
      label,
      isRequired,
      onChange,
      onBlur,
      values,
      name,
      errors,
      preloadedValue
    },
    ref
  ) => {
    return (
      <div className="form-radio-group form-group">
        {label && (
          <div className="control-top-labels">
            <div className="form-label">
              {label}
              {isRequired && (
                <>
                  &nbsp;<sup>*</sup>
                </>
              )}
            </div>
          </div>
        )}
        {values && (
          <div className="form-radio-group__wrap">
            {values.map((item) => (
              <div
                className="form-radio-group__item"
                key={`${name}_${item.value}`}>
                <RadioButton
                  ref={ref}
                  isCustomButton
                  value={item.value}
                  text={item.text}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  isChecked={preloadedValue === item.value}
                />
              </div>
            ))}
          </div>
        )}
        {errors[name] && (
          <div className="error-message">
            <sup>*</sup> Field must be checked
          </div>
        )}
      </div>
    );
  }
);

RadioGroup.defaultProps = {
  errors: {}
};

RadioGroup.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  values: PropTypes.array,
  name: PropTypes.string,
  errors: PropTypes.object,
  preloadedValue: PropTypes.string
};

export default RadioGroup;
