import React, { useState, useEffect } from "react";
import purify from "dompurify";
import PropTypes from "prop-types";
import { generatePath, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearOrderDetails } from "@/store/order/order.actions";
import { routes } from "@/constants";
import cn from "classnames";
import {
  ClassNameByStatus,
  OrderMasaDescrByStatus,
  OrderCasaDescrByStatus
} from "@/constants/orderStatuses";
import { ClockIcon, TagIcon } from "@/icons";
import { usePopperTooltip } from "react-popper-tooltip";
import "./ProjectCard.scss";

const ProjectCard = ({ project }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("-");
  const [serviceName, setServiceName] = useState(false);
  const [description, setDescription] = useState(false);
  const {
    organization_name,
    application_name,
    package_name,
    service_name,
    status: { code, name },
    id,
    gcp_project,
    due_date
  } = project;
  const [controlledVisible, setControlledVisible] = useState(false);
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible
  } = usePopperTooltip({
    offset: 6,
    trigger: "hover",
    closeOnOutsideClick: true,
    interactive: true,
    placement: "top",
    visible: controlledVisible,
    onVisibleChange: setControlledVisible
  });

  useEffect(() => {
    switch (service_name) {
      case "casa":
        setTitle(application_name || organization_name);
        setServiceName("Cloud Application Security Assessment");
        setDescription(OrderCasaDescrByStatus[code]);
        break;
      case "masa":
        setTitle(package_name || organization_name);
        setServiceName("Mobile Application Security Assessment");
        setDescription(OrderMasaDescrByStatus[code]);
        break;
      default:
        break;
    }
  }, [organization_name, application_name, package_name, service_name, code]);

  const handleOpenOrderView = async () => {
    await dispatch(clearOrderDetails());

    navigate(generatePath(routes.PROJECT_DETAILS, { id }));
  };

  return (
    <button
      className="project-card"
      onClick={handleOpenOrderView}
      type="button">
      <div className="project-card__panel">
        {name && (
          <div
            className={cn(
              "project-card__badge",
              code && ClassNameByStatus[code]
            )}>
            {name}
          </div>
        )}
      </div>
      <div className="project-card__head">
        <div
          className="project-card__title"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: purify.sanitize(title) }}
        />
        <div className="project-card__subtitle">{serviceName}</div>
      </div>
      {description && <div className="project-card__text">{description}</div>}
      {service_name === "casa" && (
        <div className="project-card__tags">
          {gcp_project && (
            <div className="project-card__tag">
              <TagIcon className="project-card__tag-icon" />
              <span className="project-card__tag-label">
                GCP Project number: {gcp_project}
              </span>
            </div>
          )}
          {due_date && (
            <div className="project-card__tag" ref={setTriggerRef}>
              <ClockIcon className="project-card__tag-icon" />
              <span className="project-card__tag-label">{due_date}</span>
            </div>
          )}
          {visible && (
            <div
              ref={setTooltipRef}
              {...getTooltipProps({ className: "tooltip-container" })}>
              Assessment due date
              <div {...getArrowProps({ className: "tooltip-arrow" })} />
            </div>
          )}
        </div>
      )}
    </button>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.object,
    is_paid: PropTypes.bool,
    payed_at: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    package_name: PropTypes.string,
    organization_name: PropTypes.string,
    application_name: PropTypes.string,
    due_date: PropTypes.string,
    service_name: PropTypes.string,
    order_number: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    gcp_project: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
  }).isRequired
};

export default ProjectCard;
