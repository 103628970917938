import React, { useEffect, useState } from "react";
import purify from "dompurify";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "@/constants";
import { getOrderDetails } from "@/store/order/order.reducer";
import { isInProcess } from "@/store/pending/pending.reducer";
import {
  OrderActions,
  getOrderCasaDetailsById,
  getOrderDetailsById
} from "@/store/order/order.actions";
import CircleSpinner from "@/components/CircleSpinner";
import InfoImage from "@/assets/images/info.gif";
import AnimationImage from "@/components/AnimationImage";
import ProjectDetailsCasa from "@/components/ProjectDetails/components/ProjectDetailsCasa";
import ProjectDetailsMasa from "@/components/ProjectDetails/components/ProjectDetailsMasa";
import cn from "classnames";
import { ClassNameByStatus } from "@/constants/orderStatuses";
import { Lg } from "@/components/Responsive";
import "./ProjectDetails.scss";

const ProjectDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const orderDetails = useSelector(getOrderDetails);
  const [projectName, setProjectName] = useState();
  const [title, setTitle] = useState("-");
  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.GET_ORDER_DETAILS_BY_ID)
  );

  useEffect(() => {
    (async () => {
      try {
        const casaDetails = await dispatch(getOrderCasaDetailsById(id));

        if (!casaDetails) {
          dispatch(getOrderDetailsById(id));
        }
      } catch (err) {
        navigate(routes.ROOT);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (orderDetails && orderDetails.service_name === "casa") {
      setProjectName("casa");
      setTitle(
        orderDetails.application_name ||
          `${orderDetails.organization_name}’s application`
      );
    }
    if (orderDetails && orderDetails.service_name === "masa") {
      setProjectName("masa");
      setTitle(
        orderDetails.package_name ||
          `${
            orderDetails.organization_name || orderDetails.developer_name
          }’s application`
      );
    }
  }, [orderDetails]);

  if (isLoading) {
    return (
      <div className="project-details">
        <div className="project-details__loading">
          <CircleSpinner />
        </div>
      </div>
    );
  }

  if (id && !orderDetails) {
    return null;
  }

  const {
    status: { code, name }
  } = orderDetails;

  const renderInfoWidget = () => {
    return (
      <div className="project-details__widget">
        <div className="project-details__widget-image">
          {projectName === "casa" && <AnimationImage title="project-details" />}
          {projectName === "masa" && (
            <img
              className="project-details__widget-image-i"
              src={InfoImage}
              alt=""
              width={142}
              height={142}
            />
          )}
        </div>
        <div className="project-details__widget-content">
          <div className="project-details__widget-title">
            {projectName === "casa" && "See the full picture"}
            {projectName === "masa" &&
              "Want a comprehensive assessment of your application?"}
          </div>
          <div className="project-details__widget-text">
            {projectName === "casa" &&
              "Gain holistic insights with our comprehensive assessment. Get a discount for a pentest with your CASA validation."}
            {projectName === "masa" &&
              "Contact us  today and get a discount for a pentest with your CASA verification"}
          </div>
          <div className="project-details__widget-footer">
            <a
              className="project-details__widget-button btn btn-light"
              href="mailto:hub@leviathansecurity.com">
              Contact us
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="project-details">
      <div className="project-details__body">
        <div className="project-details__head">
          <div className="project-details__head-wrap">
            <h1
              className="project-details__title"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: purify.sanitize(title) }}
            />
            <div className="project-details__subtitle">
              {projectName === "casa" &&
                "Cloud Application Security Assessment"}
              {projectName === "masa" &&
                "Mobile Application Security Assessment"}
            </div>
          </div>
          <div className="project-details__head-aside">
            {name && (
              <div
                className={cn(
                  "project-details__badge",
                  code && ClassNameByStatus[code]
                )}>
                {name}
              </div>
            )}
          </div>
        </div>
        {projectName === "casa" && (
          <ProjectDetailsCasa
            orderDetails={orderDetails}
            infoWidget={renderInfoWidget()}
          />
        )}
        {projectName === "masa" && (
          <ProjectDetailsMasa
            orderDetails={orderDetails}
            infoWidget={renderInfoWidget()}
          />
        )}
      </div>
      <Lg>
        <div className="project-details__aside">{renderInfoWidget()}</div>
      </Lg>
    </div>
  );
};

export default ProjectDetails;
