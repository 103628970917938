import React from "react";
import { PropTypes } from "prop-types";
import { generatePath, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@/components/Button";
import InputField from "@/components/InputField";
import { routes } from "@/constants";
import { useForm } from "react-hook-form";
import yup from "@/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { postInvoiceCasa, OrderActions } from "@/store/order/order.actions";
import { isInProcess } from "@/store/pending/pending.reducer";
import PaymentTotalInfo from "@/components/OrderStepsCasa/PaymentTotalInfo";
import PlacesAutocompleteControl from "@/components/PlacesAutocompleteControl";
import Checkbox from "@/components/Checkbox";
import numeral from "numeral";

const schema = yup.object({
  order_email: yup.string().nullable().max(255).email().required(),
  order_address: yup.object().nullable().required(),
  memo: yup.string().nullable().max(255),
  order_transaction_settlement: yup.boolean().nullable()
});

const Invoice = ({ selectedPlan, orderInfo }) => {
  const { id } = useParams();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema)
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.POST_INVOICE)
  );

  const onSubmit = async (data) => {
    data.order_date = orderInfo.created_at;
    data.order_number = orderInfo.id.toString();
    data.order_google_place_id = data.order_address.value.place_id;
    data.order_address = data.order_address.label;
    data.plane_id = selectedPlan.id;

    await dispatch(postInvoiceCasa({ id, data }));

    const path = generatePath(routes.ORDER_CASA_SUCCESS_INVOICE, { id });
    navigate(path);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="invoice-form">
      <div className="fields-group-title">Invoicing details</div>
      <InputField
        name="order_email"
        type="email"
        label="Accounting / Accounts payable contact email <sup>*</sup>"
        errors={errors}
        placeholder="example@mail.com"
        {...register("order_email")}
      />
      <PlacesAutocompleteControl
        name="order_address"
        control={control}
        label="Billing address <sup>*</sup>"
        placeholder="Start typing the address"
        errors={errors}
      />
      <InputField
        multiline="2"
        name="memo"
        type="text"
        label="Additional notes or instructions"
        errors={errors}
        {...register("memo")}
      />
      <Checkbox
        text="Check this box if you are planning to use a Purchase Order for transaction settlement."
        name="order_transaction_settlement"
        errors={errors}
        {...register("order_transaction_settlement")}
      />
      {selectedPlan && (
        <PaymentTotalInfo
          title={`CASA ${orderInfo.tier} - ${selectedPlan.product.name} Assessment`}
          amount={numeral(selectedPlan.price / 100).format("0,0")}
          totalAmount={numeral(selectedPlan.price / 100).format("0,0")}
        />
      )}
      <div className="main-layout__drawer-footer">
        <div className="main-layout__drawer-buttons">
          <div className="main-layout__drawer-buttons-item">
            <Button
              isLoading={isLoading}
              text="Submit"
              className="btn-default btn-fullwidth"
              type="submit"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

Invoice.propTypes = {
  selectedPlan: PropTypes.object,
  orderInfo: PropTypes.object
};

export default Invoice;
