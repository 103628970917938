export const OrderFilters = {
  NEW: "new",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed"
};

export const FilterByIndex = {
  0: OrderFilters.NEW,
  1: OrderFilters.IN_PROGRESS,
  2: OrderFilters.COMPLETED
};

export const IndexByFilter = {
  [OrderFilters.NEW]: 0,
  [OrderFilters.IN_PROGRESS]: 1,
  [OrderFilters.COMPLETED]: 2
};

export const OrderStatuses = {
  CREATED: "created",
  PENDING_EMAIL_VERIFICATION: "pending_email_verification",
  EMAIL_VERIFIED: "email_verified",
  NEED_MORE_INFO: "need_more_info",
  ACCEPTED: "accepted",
  SCHEDULED: "scheduled",
  ASSESSMENT_IN_PROGRESS: "assessment_in_progress",
  MITIGATIONS_NEEDED: "mitigations_needed",
  QUOTE_READY: "quote_ready",
  SCOPING: "scoping",
  CONFIRMING_PURCHASE_ORDER: "confirming_purchase_order",
  PURCHASE_ORDER_CONFIRMED: "purchase_order_confirmed",
  PAID: "paid",
  VERIFYING_MITIGATIONS: "verifying_mitigations",
  VALIDATED: "validated",
  NOT_VALIDATED: "not_validated",
  VERIFIED: "verified",
  NOT_VERIFIED: "not_verified",
  AWAITING_PAYMENT: "awaiting_payment",
  INVOICE_PROCESSING: "invoice_processing",
  INVOICE_DETAILS_CONFIRMED: "invoice_details_confirmed"
};

export const ClassNameByStatus = {
  [OrderStatuses.PENDING_EMAIL_VERIFICATION]: "warning",
  [OrderStatuses.EMAIL_VERIFIED]: "success",
  [OrderStatuses.NEED_MORE_INFO]: "error",
  [OrderStatuses.ACCEPTED]: "success",
  [OrderStatuses.SCHEDULED]: "warning",
  [OrderStatuses.ASSESSMENT_IN_PROGRESS]: "warning",
  [OrderStatuses.MITIGATIONS_NEEDED]: "error",
  [OrderStatuses.CREATED]: "created",
  [OrderStatuses.QUOTE_READY]: "quote-ready",
  [OrderStatuses.SCOPING]: "warning",
  [OrderStatuses.CONFIRMING_PURCHASE_ORDER]: "warning",
  [OrderStatuses.PURCHASE_ORDER_CONFIRMED]: "success",
  [OrderStatuses.PAID]: "success",
  [OrderStatuses.VERIFYING_MITIGATIONS]: "error",
  [OrderStatuses.VERIFIED]: "success",
  [OrderStatuses.NOT_VERIFIED]: "error",
  [OrderStatuses.VALIDATED]: "success",
  [OrderStatuses.NOT_VALIDATED]: "error",
  [OrderStatuses.INVOICE_PROCESSING]: "warning",
  [OrderStatuses.INVOICE_DETAILS_CONFIRMED]: "success",
  [OrderStatuses.AWAITING_PAYMENT]: "warning"
};

export const OrderMasaDescrByStatus = {
  [OrderStatuses.CREATED]:
    "Continue to provide the information to start MASA validation.",
  [OrderStatuses.PENDING_EMAIL_VERIFICATION]:
    "We will get back to you when your quote is ready.",
  [OrderStatuses.EMAIL_VERIFIED]:
    "Choose a pricing plan that suits your needs.",
  [OrderStatuses.NEED_MORE_INFO]:
    "We will get back to you when your quote is ready.",
  [OrderStatuses.ACCEPTED]: "We will get back to you when your quote is ready.",
  [OrderStatuses.PAID]:
    "We successfully confirmed your payment. Assessment will start according to the option you chose.",
  [OrderStatuses.INVOICE_PROCESSING]:
    "Hang tight! Our accounting team will shortly reach out to the Accounts Payable/Accounting contact you provided to finalize invoice details.",
  [OrderStatuses.INVOICE_DETAILS_CONFIRMED]:
    "Invoicing details have been verified and approved; invoice is being prepared. Assessment will start according to the option you chose.",
  [OrderStatuses.SCHEDULED]:
    "The assessment date is locked in! The MASA validation for your application is scheduled and will commence as outlined in the plan you selected.",
  [OrderStatuses.ASSESSMENT_IN_PROGRESS]:
    "The assessment is in motion! Our team is at work, and you'll hear from us with a report upon completion.",
  [OrderStatuses.MITIGATIONS_NEEDED]:
    "Your MASA findings report is now available for download. The report outlines findings that have to be fixed to complete MASA validation. We kindly urge you to address each identified issue. Upon completion of the necessary fixes, and once you are prepared for a review of the remediated areas, please select the “Ready for Revalidation” option. This will signal our team to re-engage and verify the remediations implemented.",
  [OrderStatuses.VERIFYING_MITIGATIONS]:
    "We are in the process of verifying the effectiveness of the remediation steps taken. This phase ensures that all identified weaknesses are properly mitigated.",
  [OrderStatuses.VERIFIED]:
    "Congratulations! Your application has successfully met the MASA security requirements. The implemented remediations have been validated. Please consult the Findings Report for details on the findings and the Validation Report for confirmation of meeting the MASA requirements.",
  [OrderStatuses.NOT_VERIFIED]: "The application has not been validated."
};

export const OrderCasaDescrByStatus = {
  [OrderStatuses.CREATED]:
    "Continue to provide the information to start CASA validation.",
  [OrderStatuses.SCOPING]:
    "We are scoping the project. We will get back to you when your quote is ready.",
  [OrderStatuses.QUOTE_READY]: "Quote for the assessment is ready.",
  [OrderStatuses.CONFIRMING_PURCHASE_ORDER]:
    "Hang tight! Our accounting team will shortly reach out to the Accounts Payable/Accounting contact you provided to finalize invoice details.",
  [OrderStatuses.PURCHASE_ORDER_CONFIRMED]:
    "Invoicing details have been verified and approved; invoice is being prepared. Assessment will start according to the option you chose.",
  [OrderStatuses.PAID]:
    "We successfully confirmed your payment. Assessment will start according to the option you chose.",
  [OrderStatuses.ASSESSMENT_IN_PROGRESS]:
    "The assessment is in motion! Our team is at work, and you'll hear from us with a report upon completion.",
  [OrderStatuses.SCHEDULED]:
    "The assessment date is locked in! The CASA validation for your application is scheduled and will commence as outlined in the plan you selected.",
  [OrderStatuses.MITIGATIONS_NEEDED]:
    "Your CASA findings report is now available for download. The report outlines findings that have to be fixed to complete CASA validation. We kindly urge you to address each identified issue. Upon completion of the necessary fixes, and once you are prepared for a review of the remediated areas, please select the “Ready for Revalidation” option. This will signal our team to re-engage and verify the remediations implemented.",
  [OrderStatuses.VERIFYING_MITIGATIONS]:
    "We are in the process of verifying the effectiveness of the remediation steps taken. This phase ensures that all identified weaknesses are properly mitigated.",
  [OrderStatuses.VERIFIED]:
    "Congratulations! Your application has successfully met the CASA security requirements. The implemented remediations have been validated. Please consult the Findings Report for details on the findings and the Validation Report for confirmation of meeting the CASA requirements.",
  [OrderStatuses.NOT_VERIFIED]: "The application has not been validated."
};
