/* eslint-disable no-console */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Invoice from "@/components/OrderSteps/Invoice";
import OnlinePayment from "@/components/OrderSteps/OnlinePayment";
import { getOrderProducts } from "@/store/order/order.reducer";
import { fetchOrderProducts } from "@/store/order/order.actions";
import cn from "classnames";
import "./PaymentType.scss";

const PaymentType = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [selectedPayment, setSelectedPayment] = useState();
  const [isInvoice, setIsInvoice] = useState(false);
  const list = useMemo(
    () => [
      {
        id: "online",
        title: "Online Payment",
        isActive: true
      },
      {
        id: "invoice",
        title: "Invoice"
      }
    ],
    []
  );
  const orderProducts = useSelector(getOrderProducts);
  const productId = searchParams.get("product_id");
  const [selectedPlan, setSelectedPlan] = useState();

  useEffect(() => {
    if (!orderProducts?.length) {
      dispatch(fetchOrderProducts());
    }
    // eslint-disable-next-line
  }, [orderProducts]);

  const handleChoosePayment = useCallback((pId) => {
    setSelectedPayment(pId);

    switch (pId) {
      case "online":
        setIsInvoice(false);
        break;
      case "invoice":
        setIsInvoice(true);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    setSelectedPayment(list[0].id);
    handleChoosePayment(list[0].id);
    // eslint-disable-next-line
  }, [list, handleChoosePayment, id]);

  useEffect(() => {
    setSelectedPlan(
      orderProducts.find((p) => p.id === parseInt(productId, 10))
    );
  }, [orderProducts, productId]);

  return (
    <div className="payment-type">
      <div className="payment-type__inner">
        <div className="payment-type__nav">
          {list.length &&
            list?.map((item) => (
              <button
                className={cn(
                  "payment-type__item",
                  selectedPayment === item.id && "active"
                )}
                key={item.id}
                type="button"
                onClick={() => handleChoosePayment(item.id)}>
                <div className="payment-type__item-wrap">
                  {item.title && (
                    <div className="payment-type__item-title">{item.title}</div>
                  )}
                </div>
              </button>
            ))}
        </div>
        {selectedPlan && (
          <div className="payment-type__body">
            {isInvoice ? (
              <Invoice selectedPlan={selectedPlan} />
            ) : (
              <OnlinePayment selectedPlan={selectedPlan} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentType;
