import React, { useMemo, useRef, useState } from "react";
import InputField from "@/components/InputField";
import InputPasswordField from "@/components/InputPasswordField";
import yup from "@/utils/yup";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import Checkbox from "@/components/Checkbox";
import Button, { ButtonLink } from "@/components/Button";
import { MAX_APK_FILE_SIZE_IN_MEGABYTES, routes } from "@/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { isInProcess } from "@/store/pending/pending.reducer";
import {
  OrderActions,
  postApplicationInfo,
  uploadFileSuccessful
} from "@/store/order/order.actions";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ResumableFileUploader from "@/components/ResumableFileUploader";

const schema = yup.object({
  application_name: yup.string().nullable().max(255).required(),
  package_name: yup.string().nullable().max(255).required(),
  has_login: yup.boolean().nullable(),
  is_paid: yup.boolean().nullable(),
  app_login: yup.string().nullable().when("has_login", {
    is: true,
    then: yup.string().nullable().required()
  }),
  app_password: yup.string().nullable().when("has_login", {
    is: true,
    then: yup.string().nullable().required()
  }),
  app_filename: yup
    .mixed()
    .nullable()
    // eslint-disable-next-line camelcase,consistent-return
    .when(["is_paid"], (is_paid) => {
      // eslint-disable-next-line camelcase
      if (is_paid) {
        return yup
          .mixed()
          .test("ifString", "Required", (value) => {
            if (typeof value === "string") {
              return !!value;
            }

            return true;
          })
          .test(
            "size",
            `Size of file can't be larger than ${MAX_APK_FILE_SIZE_IN_MEGABYTES} Mb`,
            (value) => {
              if (typeof value === "string") {
                return true;
              }
              // eslint-disable-next-line no-unsafe-optional-chaining
              const sizeMB = value?.size / 1024 / 1024;
              return sizeMB <= MAX_APK_FILE_SIZE_IN_MEGABYTES;
            }
          )
          .test("type", "Only .apk files are allowed", (value) => {
            if (typeof value === "string") {
              return !!value;
            }

            return (
              value?.type === "application/vnd.android.package-archive" ||
              value?.type === "application/octet-stream" ||
              value?.name.endsWith(".apk")
            );
          });
      }
    })
});

const ApplicationInfoForm = ({ predefinedValues }) => {
  const fileUploader = useRef();
  const navigate = useNavigate();
  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.POST_APPLICATION_INFO)
  );

  const [isFileLoading, setIsFileLoading] = useState(false);
  const { id } = useParams();

  const fileUploadURL = useMemo(() => {
    return `${process.env.REACT_APP_API_URL}/api/v1/order/${id}/application-info/file`;
  }, [id]);

  const dispatch = useDispatch();

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    resetField,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: predefinedValues
  });
  const watchHasLogin = watch("has_login");
  const watchIsPaid = watch("is_paid");

  const uploadFile = () => {
    fileUploader.current?.startUploading();
  };

  const onFileUploadSuccess = (_file, orderDetails) => {
    setIsFileLoading(false);

    if (orderDetails) {
      dispatch(uploadFileSuccessful(orderDetails));
    }

    const path = generatePath(routes.AGREEMENT, { id });
    navigate(path);
  };

  const onSubmit = async (data) => {
    data.has_login = +data.has_login;
    data.is_paid = +data.is_paid;
    if (!watchHasLogin) {
      data.app_login = "";
      data.app_password = "";
    }
    const payload = Object.keys(data).reduce((acc, key) => {
      if (key !== "app_filename") {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    if (fileUploader.current?.resumable.files.length) {
      await dispatch(
        postApplicationInfo({
          id,
          data: payload
        })
      );

      uploadFile();

      return;
    }

    await dispatch(
      postApplicationInfo({
        id,
        data: payload
      })
    );

    const path = generatePath(routes.AGREEMENT, { id });
    navigate(path);
  };

  return (
    <form className="application-info" onSubmit={handleSubmit(onSubmit)}>
      <div className="fields-group fields-group_bottom-offset">
        <div className="fields-group-title">Application details</div>
        <InputField
          name="application_name"
          type="text"
          label="Application name <sup>*</sup>"
          errors={errors}
          placeholder="Application name"
          {...register("application_name")}
        />
        <InputField
          name="package_name"
          type="text"
          tooltipTitle="Where to find it?"
          tooltipContent={
            <div className="where-to-find-tooltip">
              <ol className="where-to-find-tooltip-list">
                <li className="where-to-find-tooltip-list__item">
                  Go to the Google Play Console:{" "}
                  <a
                    href="https://play.google.com/console/"
                    target="_blank"
                    rel="noreferrer">
                    https://play.google.com/console/
                  </a>
                </li>
                <li className="where-to-find-tooltip-list__item">
                  Go to «All apps»
                </li>
                <li className="where-to-find-tooltip-list__item">
                  Find your application&apos;s package name under the
                  application&apos;s name
                </li>
              </ol>
            </div>
          }
          errors={errors}
          label="Package name <sup>*</sup>"
          placeholder="Package name"
          {...register("package_name")}
        />
      </div>
      <div className="fields-group">
        <Checkbox
          text="Application requires user login"
          name="has_login"
          errors={errors}
          {...register("has_login")}
        />
        {watchHasLogin && (
          <div className="fields-group fields-group_bottom-offset">
            <div className="fields-group-title">Login credentials</div>
            <div className="fields-group-subtitle">
              Provide a set of valid credentials that we can use for testing
              purposes. The credentials shared will be securely handled and
              exclusively used for assessment purposes.
            </div>
            <InputField
              name="app_login"
              type="text"
              label="Username"
              errors={errors}
              placeholder="Email (e.g. user@yourapp.com)"
              {...register("app_login")}
            />
            <InputPasswordField
              name="app_password"
              errors={errors}
              label="Password"
              placeholder="Password"
              {...register("app_password")}
            />
          </div>
        )}
        <Checkbox
          text="Application is paid or unlisted (upload APK)"
          name="is_paid"
          errors={errors}
          {...register("is_paid")}
        />
        {watchIsPaid && (
          <div className="fields-group fields-group_bottom-offset">
            <div className="fields-group-title">APK file</div>
            <div className="fields-group-subtitle">
              If your application is a paid app or hasn’t been released on the
              Google Play Store, please upload the APK file directly.
            </div>
            <ResumableFileUploader
              name="app_filename"
              fileAcceptLabel="Only .apk"
              label="If your application is not yet released, or is a paid application, provide APK file of your application. (optional)"
              setValue={setValue}
              resetField={resetField}
              target={fileUploadURL}
              ref={fileUploader}
              errors={errors}
              defaultFilename={predefinedValues.app_filename}
              onFileProgress={() => setIsFileLoading(true)}
              onFileUploadPause={() => setIsFileLoading(false)}
              onAfterFileSuccess={onFileUploadSuccess}
              onAfterUploadCanceled={() => setIsFileLoading(false)}
              onAfterFileError={() => {
                setIsFileLoading(false);
              }}
              control={control}
              onRemoveFile={() => setIsFileLoading(false)}
            />
          </div>
        )}
      </div>
      <div className="main-layout__drawer-footer">
        <div className="main-layout__drawer-buttons">
          <div className="main-layout__drawer-buttons-item">
            <ButtonLink
              text="Back"
              className="btn-light btn-fullwidth"
              to={generatePath(routes.ORDER_BY_ID, { id })}
            />
          </div>
          <div className="main-layout__drawer-buttons-item">
            <Button
              isLoading={isLoading || isFileLoading}
              type="submit"
              className="btn-default btn-fullwidth"
              text="Continue"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

ApplicationInfoForm.propTypes = {
  predefinedValues: PropTypes.object
};

export default ApplicationInfoForm;
